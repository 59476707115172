import {motion} from "framer-motion"
import React from "react"
import styled from "styled-components"




const Button =styled.div
`
  z-index:99;
  cursor:pointer;

`


const Path = (props) =>(
  <motion.path
    fill="transparent"
    strokeLincap="round"
    strokeWidth="3"
    {...props}
  />
);


const Transition= { duration: 0.33};



export default function Menu( {toggle, isOpen} ){

      console.log(isOpen);
      console.log(toggle);
      return(
        <Button onClick={toggle}>
          <svg width="23" height="23" viewBox="0 0 23 23">
            <Path
                animate= {isOpen ? "open" : "closed"}
                initial={false}
                variants={{
                        closed: { d: "M 2 2.5 L 20 2.5", stroke: "hsl(0, 0%, 18%)" },
                        open: { d: "M 3 16.5 L 17 2.5", stroke: "hsl(0, 0%, 18%)" },
                      }}
                transition={Transition}
            />
            <Path
                d="M 2 9.423 L 20 9.423"
                stroke="hsl(0, 0%, 18%)"
                animate={isOpen ? "open" : "closed"}
                initial={false}
                variants={{
                      closed: { opacity: 1 },
                      open: { opacity: 0 },
                    }}
                transition={Transition}
            />
            <Path
               animate={isOpen ? "open" : "closed"}
               initial={false}
               variants={{
                  closed: { d: "M 2 16.346 L 20 16.346", stroke: "hsl(0, 0%, 18%)" },
                  open: { d: "M 3 2.5 L 17 16.346", stroke: "hsl(0, 0%, 18%)" },
                  }}
              transition={Transition}
            />
          </svg>
          </Button>
      )

}
