import sanityClient from '@sanity/client';





export default sanityClient({

    projectId:"myl7azx3",
    dataset:"production"

})
